@use "variables" as *;
@use "./mixins/font" as *;
@use "./mixins/layout" as *;
@use "./mixins/media" as *;
@use "./mixins/reset" as *;
@use "./mixins/support" as *;
@use "./mixins/typography" as *;

@forward "./mixins/font";
@forward "./mixins/layout";
@forward "./mixins/media";
@forward "./mixins/reset";
@forward "./mixins/support";
@forward "./mixins/typography";

// --------------------------------------------------------

@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  @include font-smoothing;
  margin: 0;
  padding: 0;
  color: var(--black);
  font-family: var(--font-main);
  font-size: 16px;
  line-height: 150%;
  background-color: var(--bright-blue);
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

h1,
h2 {
  font-family: var(--font-condensed);
  font-size: 50px;
  line-height: 60px;
  color: var(--black);
  margin: 0 0 20px 0;
  font-weight: 400;
}

h3 {
  color: var(--purple);
  font-size: 22px;
  font-family: var(--font-main);
  line-height: 30px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0 0 10px 0;
}

p {
  font-family: var(--font-main);
  color: var(--black);
  font-family: var(--font-main);
  font-size: 16px;
  line-height: 22px;
}

@include media($medium-lowest, down) {
  h1,
  h2 {
    font-size: 40px;
    line-height: 48px;
  }

  h3 {
    font-size: 18px;
    line-height: 26px;
  }
}

@include media($x-small, down) {
  h1,
  h2 {
    font-size: 34px;
    line-height: 42px;
  }

  h3 {
    font-size: 16px;
    line-height: 24px;
  }
}
