@use "src/styles/global-styles.scss" as *;

.container {
  background-color: var(--bright-blue);
  width: 100%;
  height: 80px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    svg {
      color: var(--white);
      width: 90px;
      position: relative;
      top: 2px;
    }
  }

  button {
    background-color: var(--white);
    color: var(--black);
    font-family: var(--font-main);
    font-weight: 700;
    border-radius: 30px;
    border: 0;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: color var(--transition), background-color var(--transition);

    svg {
      width: 16px;
      margin-right: 4px;
      position: relative;
      top: 1px;
    }

    @include hover {
      background-color: var(--purple);
      color: var(--white);
    }
  }

  @include media($small, down) {
    height: 50px;
    padding: 0 20px;

    .logo {
      svg {
        width: 50px;
        position: relative;
        top: 3px;
      }
    }

    button {
      font-size: 14px;
      font-weight: 500;
      padding: 7px 16px;
    }
  }
}
