@use "src/styles/global-styles.scss" as *;

.container {
  width: 100%;
  padding: 80px 30px;
  background-color: var(--purple);

  @include media($small, down) {
    padding: 60px 20px;
  }
  .inner {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
  }
}

.section2_title {
  color: var(--white);
  margin: 0 0 20px 0;
}

.section2 {
  flex-wrap: wrap;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  div {
    margin-top: 40px;
    width: calc((100% / 3) - 20px);
    background-color: var(--white);
    position: relative;
    border-radius: var(--border-radius);
    padding: 20px;

    h4 {
      border-radius: var(--border-radius);
      padding: 8px 20px;
      color: var(--white);
      font-family: var(--font-main);
      font-weight: 700;
      line-height: 20px;
      font-size: 18px;
      position: absolute;
      margin: 0;
      top: -18px;
      left: 20px;
      &.teal {
        background-color: var(--bright-blue-darker);
      }
      &.orange {
        background-color: var(--orange);
      }
      &.green {
        background-color: var(--green);
      }
    }
    p {
      margin: 16px 0 0 0;
    }
  }
  @include media(1060px, down) {
    div {
      width: calc(50% - 20px);
    }
  }
  @include media(750px, down) {
    div {
      width: 100%;
    }
  }
  @include media(390px, down) {
    div {
      h4 {
        font-size: 16px;
        left: 10px;
      }
    }
  }
}
