@use "src/styles/global-styles.scss" as *;

.container {
  width: 100%;
  padding: 80px 30px;
  background-color: var(--white);

  @include media($small, down) {
    padding: 60px 20px;
  }
  .inner {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
  }
}

.section1_top {
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    width: calc(50% - 20px);
  }

  img {
    width: calc(50% - 20px);
  }

  .button {
    background-color: var(--purple);
    color: var(--white);
    font-family: var(--font-main);
    font-weight: 700;
    border-radius: 30px;
    border: 0;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color var(--transition);
    text-decoration: none;
    margin-top: 10px;
    display: inline-flex;

    svg {
      width: 16px;
      margin-left: 4px;
      position: relative;
      top: 1px;
    }

    @include hover {
      background-color: var(--purple-darker);
    }
  }

  @include media($small-highest, down) {
    flex-wrap: wrap;
    div {
      width: 100%;
      margin: 0 auto;
    }

    img {
      width: 100%;
      max-width: 500px;
      margin: 30px auto 0 auto;
    }
  }
}

.section1_bottom {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 40px;
  p {
    width: calc(50% - 20px);
    background-color: var(--extra-light-gray);
    border-radius: var(--border-radius);
    padding: 20px;
    margin: 0;
    strong {
      color: var(--purple);
    }
  }

  @include media($small-lowest, down) {
    flex-wrap: wrap;
    p {
      width: 100%;
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
