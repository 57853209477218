@use "src/styles/global-styles.scss" as *;

.container {
  width: 100%;
  padding: 80px 30px;
  background-color: var(--white);

  @include media($small, down) {
    padding: 60px 20px;
  }
  .inner {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
  }
}

.section3 {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    width: calc(50% - 20px);
  }

  img {
    width: calc(50% - 20px);
    max-width: 480px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 30px 0 0 0;
    li {
      background-color: var(--extra-light-gray);
      border: 1px solid var(--medium-gray);
      border-radius: var(--border-radius);
      margin-bottom: 20px;
      padding: 10px 20px 10px 40px;
      display: inline-block;
      margin-right: 20px;
      position: relative;
    }
    svg {
      color: var(--purple);
      width: 18px;
      position: absolute;
      top: 11px;
      left: 12px;
    }
  }

  @include media($small-highest, down) {
    div {
      width: calc(60% - 20px);
    }

    img {
      width: calc(40% - 20px);
    }
  }

  @include media($small, down) {
    flex-direction: column-reverse;
    div {
      width: 100%;
    }

    img {
      width: 100%;
      margin-top: 40px;
      max-width: 400px;
    }
  }
}
